import { Link, navigate } from "gatsby";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Header from "../../components/Header";
import { UserContext } from "../../components/Layout";
import LoadingSpinner from "../../components/LoadingSpinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { API_ROOT } from "../../constants";
import AccountErrorMessage from "../../components/AccountErrorMessage";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
`;

const Form = styled.form`
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 300px;
  font-size: 22px;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 60px;
  position: relative;
  background: var(--accent-bg);

  * {
    font-size: inherit;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      color: #222;
    }

    input {
      border: 1px solid rgb(0 0 0 / 90%);
      box-sizing: border-box;
      margin: 3px;
      padding: 3px;
      font-size: 16px;
      line-height: 26px;
      border-radius: 3px;
    }
  }

  #forgot-password {
    padding-left: 8px;
    color: blue;
    font-size: 16px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  button {
    box-sizing: border-box;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
    align-self: center;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function Login() {
  const user = useContext(UserContext);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be 6 characters or more")
        .required("Password is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    onSubmit: async (values) => {
      const { data } = await axios.post(API_ROOT + "users/login", {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim().toLowerCase(),
      });
      localStorage.setItem("token", data.token);
      user.setUser(data.user);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      navigate("/app/videos/1");
    },
  });

  if (user.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container>
        <Header>Login</Header>
        <Form onSubmit={formik.handleSubmit}>
          <AccountErrorMessage
            touched={formik.touched}
            error={error}
            formikErrors={formik.errors}
          />
          <div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                name="email"
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            <label htmlFor="password">Password:</label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </div>

          <Link id="forgot-password" to="/account/reset-password">
            Forgot password
          </Link>
          <button type="submit">Submit</button>
          <Link to="/account">Or Create Account</Link>
        </Form>
      </Container>
    </>
  );
}

export default Login;
