import React from "react";
import styled, { css, keyframes } from "styled-components";

import FadeLoader from "react-spinners/FadeLoader";

const fadeIn = keyframes`
    from {
        opacity: 0;

    } to {
        opacity: 1;
    }
`;

const Container = styled.div`
  margin: 200px auto 0 auto;
  width: min-content;
  span > span {
    background-color: var(--color) !important;
  }
  ${(props) =>
    props.isDelayed &&
    css`
      opacity: 0;
      animation-duration: 500ms;
      animation-name: ${fadeIn};
      animation-delay: 1s;
      animation-fill-mode: forwards;
    `}
`;

function LoadingSpinner(props) {
  const { isDelayed = true } = props;
  return (
    <Container isDelayed={isDelayed}>
      <FadeLoader />
    </Container>
  );
}

export default LoadingSpinner;
